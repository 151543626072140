import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import RoleRow from "../components/RoleListing"
import SiteRow from "../components/SiteListing";
import banner from "../images/krestonshirley-background-image.png";
import about_me from "../images/kreston-shirley.jpg"
import gaming_work_image from "../images/Gaming Related Work Banner.png";
import kes_consulting_logo from "../images/kes_consult.png";
import notserk_logo from "../images/notserk_logo.png";
import dc_metro_nerd from "../images/KrestonMetroNerd.png";
import kreston_page_banner from "../images/kreston-background.png";
import "../../node_modules/font-awesome/css/font-awesome.css";

import Img from "gatsby-image";

const IndexPage = () => {
    // const { allMarkdownRemark } = data // data.markdownRemark holds your post data
    // const { edges } = allMarkdownRemark

    return(
        <Layout>
            <SEO title="Hello, from Kreston Shirley"
                 description="Welcome, my name is Kreston Shirley, my craft is software development and my passions are gaming and trains. Yes, trains! Learn more here!"
                pageBanner={kreston_page_banner}
            />
            <div className="row hero-illustration">
                <img className="img-fluid" src={banner}/>
            </div>
            <div className="container-fluid">
                {/* About Me */}
                <div id="about-me-row" className="row">
                    <div className="col-sm-12">
                        <div className="container about-me-container">
                            <div id="about-me-cont" className="row about-me">
                                <div className="col-sm-12 col-md-6 about-me-text">
                                    <h3 className="text-sm-center">About Me</h3>
                                    <p>I’m a developer, gamer, railfan and entrepreneur based in my hometown Washington DC. I’ve always maintained my child like curiosity as to how this world and the universe overall works. I’ve always been fascinated with how the world works around me. </p>
                                    <p>As a young kid I found interest in engineering, meteorology, astronomy and technology. My interest in technology remained constant and eventually grew into a career. I graduated from Florida A&M University with a degree in Computer Information Systems. This degree allowed me to take classes in business which would later come into handy when working in the corporate and government space. Looking back it was inevitable that I would be in the tech space. The first half of my life I was strictly a hardware guy, it wasn’t until College I actually started programming.</p>
                                    <div className="about-me-social-links d-xs-flex justify-content-xs-center">
                                        <a className="social-links" href="https://www.linkedin.com/in/krestonshirley/"><i className="fa fa-linkedin-square"></i></a>
                                        <a className="social-links" href="https://twitter.com/krestonshirley"><i className="fa fa-twitter"></i></a>
                                        <a className="social-links" href="https://www.instagram.com/krestonshirley/"><i className="fa fa-instagram"></i></a>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 about-me-img">
                                    <img className="img-fluid" src={about_me} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <section className="row">
                        <div className="col-sm-12 section-header">
                            <h2 className="text-center">My Work</h2>
                        </div>
                        <div className="col-md-6 col-sm-12 my-work-container d-flex flex-column">
                            <img className="work-image" src={kes_consulting_logo} alt="Learn about my work in dev"/>
                            <h3 className="work-title text-center">Web Development Consulting</h3>
                            <p className="work-description">My company KES Consulting is focused on developing high quality web based solutions for small and medium sized businesses, government agencies and nonprofit organizations. To learn more about our services and previous work please refer to the link below. </p>
                            <a className="btn btn-outline-secondary align-self-center kes-button" href="https://kesconsulting.tech">Learn More</a>
                            {/*<a className="btn btn-outline-secondary align-self-center kes-button" href="#">Coming Soon</a>*/}
                        </div>
                        <div className="col-md-6 col-sm-12 my-work-container d-flex flex-column">
                            <img className="work-image" src={gaming_work_image} alt="Gaming in"/>
                            <h3 className="work-title text-center">My Gaming Industry Work</h3>
                            <p className="work-description">Over the past ten years plus, I’ve worked in various fields in the gaming space. Those various fields include education, esports and non-profit work. To learn more about my work please visit the link below.</p>
                            <Link className="btn btn-outline-secondary align-self-center kes-button" to="/gaming-work">Learn More</Link>
                        </div>
                    </section>
                    <div className="row">
                        <div id="hobbies-section" className="col-sm-12 section-header">
                            <h2 className="text-center">My Hobbies</h2>
                        </div>
                    </div>
                    {/*<div className="row">*/}
                    {/*    /!* Latest Content row *!/*/}
                    {/*</div>*/}
                    <section id="gaming-content" className="kes-section row ">
                        <div className="col-md-6 col-sm-12 section-img">
                            <img src={notserk_logo}/>
                        </div>
                        <div className="col-md-6 col-sm-12 section-text d-flex flex-column">
                            <h3 className="section-text-title text-center">Gaming Content</h3>
                            <p>In my spare time I enjoy making content around video games under my gamertag NOTSERK (My name spelled backwards). As a gamer I enjoy genres such as First person shooters, Fighters, Action Adventure and racing games. My fav game franchises are Halo and Street Fighter respectively. My content includes livestreams, lets plays and other pieces of content.</p>
                            <a className="btn btn-outline-secondary kes-button align-self-center" href="https://notserk.com">Learn More</a>
                            {/*<a className="btn btn-outline-secondary kes-button align-self-center" href="#">Coming Soon</a>*/}
                        </div>
                    </section>
                    <section id="dc-metro-nerd" className="kes-section row">
                        <div className="col-md-6 col-sm-12 section-img">
                            <img src={dc_metro_nerd}/>
                        </div>
                        <div className="col-md-6 col-sm-12 section-text d-flex flex-column">
                            <h3 className="section-text-title text-center">DC Metro Nerd</h3>
                            <p>Being a railfan is my first and oldest passion. I’ve always been obsessed with the DC Metro and random facts about the system’s inception and how they built it over the years. My goal is to create a virtual museum full of archive photos and content centered around everything DC Metro. </p>
                            {/*<a className="btn btn-outline-secondary kes-button align-self-center" href="https://dcmetronerd.com">Learn More</a>*/}
                            <a className="btn btn-outline-secondary kes-button align-self-center" href="#">Coming Soon</a>
                        </div>
                    </section>
                </div>
            </div>
        </Layout>
    )
}

// export const pageQuery = graphql`
//   query IndexQuery {
//     allMarkdownRemark {
//     edges {
//       node {
//         html
//       }
//     }
//   }}
// `

export default IndexPage
